import React from 'react'
import { Link } from 'gatsby'
import { FaMotorcycle } from 'react-icons/fa'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' url='/404' />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '1rem'
      }}
    >
      <h1>404: Page not found</h1>
      <p>
        It seems we couldn't find the page you were looking for,{' '}
        <Link to='/'>go back home...</Link>
      </p>
      <FaMotorcycle style={{ fontSize: '3rem' }} />
    </div>
  </Layout>
)

export default NotFoundPage
